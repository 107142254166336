$( document ).ready( function () {
	$( 'form' ).on( 'submit', function ( e ) {
		e.preventDefault();

		const data = $( this ).serialize();
		// POST all the data to the SMF submission endpoint URL.
		$.ajax( {
			type: 'POST',
			url: 'https://subs.sonymusicfans.com/submit',
			dataType: 'json',
			data: data,
			xhrFields: {
				withCredentials: false
			},
			success: function ( data ) {
				// Do things on successful submission.
				$( 'form' ).html( 'Thanks for signing up!' );
			},
			error: function ( err ) {
				// Do things when submission has an error.
				alert( 'An error has occurred!' );
			}
		} );
	} );



	$('.music-btn').magnificPopup({
		items: {
			src: '#music',
			type: 'inline'
		}
	});

	$('.owl-carousel').owlCarousel({
        loop:true,
        items: 1,
        dots: true,
		nav: false,
		responsive:{
			700: {
				items: 2
			}
		}
    });

} );